

























import { GetResourceModel } from '@/app_code/models/GetResourceModel'
import { PagedResultModel } from '@/app_code/models/PagedResultModel'
import { API, ServiceManager } from '@/app_code/services'
import { View } from '@/app_code/services/meta/view'
import ServiceType from '@/app_code/services/ServiceTypes'
import { Component, Mixins } from 'vue-property-decorator'
import ResourceCard from '../components/cards/ResourceCard.vue'
import Masonry from '../components/layout/Masonry.vue'
import Loader from '../components/ui/Loader.vue'

@Component({
  components: {
    Masonry,
    ResourceCard,
    Loader
  }
})
export default class Resources extends Mixins(View) {
  private posts: PagedResultModel<GetResourceModel[]> | null = null

  private api = ServiceManager.Require<API>(ServiceType.API)

  mounted (): void {
    this.loadResources()
  }

  private loadResources (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.ResourceAPI.GetResources(1)
        .then(data => {
          this.posts = data
          resolve(true)
        })
        .catch(reject)
    })
  }
}
