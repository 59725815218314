




















import Bootstrapper from '@/app_code/Bootstrapping/Bootstrapper'
import { GetResourceModel } from '@/app_code/models/GetResourceModel'
import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'
import LinkCard from './LinkCard.vue'

@Component({
  components: {
    LinkCard
  }
})
export default class ResourceCard extends Mixins(Vue) {
  @Prop({ required: true })
  private readonly resource!: GetResourceModel

  excerpt (html: string): string {
    const para = document.createElement('p')
    para.innerHTML = html
    return para.innerText.substr(0, 100).concat('...')
  }

  private getImageUrl (imageId: string): string {
    return `${Bootstrapper.Config?.API.Base}/api/files/${imageId}/download`
  }
}
